import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useMemo,
} from 'react';

import { Intl } from './createIntl';

const intlContext = createContext({} as IntlContext);

const IntlProvider: React.FC<{
	intl: Intl;
}> = (props) => {
	const [initialized, setInitialized] = useState(false);
	const [init, intl] = useMemo(() => {
		const { init, ...intl } = props.intl;
		return [init, { ...intl, t: intl.translate }];
	}, [props.intl]);
	const [value, setValue] = useState<IntlContext>(intl);

	useEffect(() => {
		const unsubscribe = intl.subscribe((event) => {
			if (event.type === 'LOADED') {
				setValue({ ...intl, currentLocale: event.payload.locale });
			}
		});
		init().then(() => {
			setInitialized(true);
		});
		return unsubscribe;
	}, [init, intl]);

	if (!initialized) {
		return null;
	}

	return (
		<intlContext.Provider value={value}>{props.children}</intlContext.Provider>
	);
};

const useIntl = () => {
	const intl = useContext(intlContext);
	return intl;
};

export { intlContext, IntlProvider, useIntl };

type IntlContext = Omit<Intl, 'init'> & {
	t: Intl['translate'];
};
